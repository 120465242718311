import React from 'react'
import intro from './images/matrimonio.jpeg'

export default ({ i18n, locale }) => (
  <div className='home'>
    <div className='home__intro'>
      <img className='home__intro-image' src={intro} alt='Felix and Simona' />
      <h1 className='home__intro-caption'>{i18n('home-header')}</h1>
    </div>

    <p>{i18n('home-thank-you')}</p>
    <p>{i18n('home-photos')}</p>
    <p>&darr; &darr; &darr; &darr; &darr; &darr;</p>
    <div className='home__form'>
      <iframe title='Photo registration' src={'https://docs.google.com/forms/d/e/1FAIpQLSc1F5gLJzJDoQBi98x9H0GW1TsMRaWO2WZgZBc1mdKYw88eTw/viewform?embedded=true&hl=' + locale} frameborder='0' marginheight='0' marginwidth='0' width='100%' height='100%'>Loading…</iframe>
    </div>
  </div>
)
