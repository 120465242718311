import React from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom'

import './app.scss'

import Navigation from './nav/module'
import Home from './home/module'
import i18n from './i18n/i18n'

class App extends React.Component {
  constructor (props) {
    super(props)
    this.state = { locale: 'en' }
    this.handleLocaleChange = this.handleLocaleChange.bind(this)
  }

  handleLocaleChange (locale = 'en') {
    this.setState({ locale })
  }

  render () {
    const localized = i18n(this.state.locale)
    return (
      <Router lang={this.state.locale}>
        <div className='canvas'>
          <Navigation i18n={localized} locale={this.state.locale} onLocaleChange={this.handleLocaleChange} />
          <div role='main'>
            <Routes>
              <Route path='/' element={<Home i18n={localized} />} />
            </Routes>
          </div>
        </div>
      </Router>
    )
  }
}

export default App
