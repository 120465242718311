import {
  pathOr,
  pipe
} from 'ramda'

import en from './en.json'
import de from './de.json'
import it from './it.json'

const defaultLocale = 'en'
const defaultKey = 'nokey'
const defaultTranslation = 'missing translation'
const translations = { de, en, it }

export default (locale = defaultLocale) => (key = defaultKey) => pipe(
  pathOr(en, [locale]),
  pathOr(defaultTranslation, [key])
)(translations)
